import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import ptbrLocale from '@fullcalendar/core/locales/pt-br';

import tippy from 'tippy.js';

var audiencias_calendar;
var plantoes_calendar;

document.addEventListener('DOMContentLoaded', function () {
  var audiencias_calendar_el = document.getElementById('agenda_audiencias');

  if (audiencias_calendar_el) {
    audiencias_calendar = new Calendar(audiencias_calendar_el, {
      plugins: [timeGridPlugin, dayGridPlugin],
      initialView: 'timeGridWeek',
      allDaySlot: false,
      slotEventOverlap: false,
      slotDuration: '00:10:00',
      weekends: false,
      scrollTime: '00:00:00',
      locale: ptbrLocale,
      headerToolbar: {
        left: 'today prev,next',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
        center: 'title'
      },
      events: {
        url: '/agendas/audiencias.json',
        extraParams: function() {
          return {
            codigo: $('#agenda_filter_unidade #codigo').val()
          };
        }
      },
      eventDidMount: function (info) {
        tippy(info.el, {
          allowHTML: true,
          theme: 'light',
          interactive: true,
          maxWidth: 500,
          popperOptions: {
            positionFixed: true
          },
          appendTo: document.body,
          content: tooltip.innerHTML,
          onShow(instance) {
            instance.popper.querySelector('.titulo').innerHTML = info.event.extendedProps.numero_processo;
            instance.popper.querySelector('.classe').innerHTML = info.event.extendedProps.classe;
            instance.popper.querySelector('.partes').innerHTML = "#"; // info.event.extendedProps.partes;
            instance.popper.querySelector('.designada').innerHTML = info.event.extendedProps.designada;
            instance.popper.querySelector('.tipo').innerHTML = info.event.extendedProps.tipo;
            instance.popper.querySelector('.nome_vara').innerHTML = info.event.extendedProps.nome_vara;
            instance.popper.querySelector('.numero_processo').innerHTML = info.event.extendedProps.numero_processo;
            
            if (info.event.extendedProps.sistema == 'themis') {
              tooltip.querySelector('.form_themis').classList.remove('d-none');
            } else {
              tooltip.querySelector('.form_themis').classList.add('d-none');
            }
            instance.popper.querySelector(".form_themis input[name='consulta.numeroUnico']").value = info.event.extendedProps.numero_processo;
          }
        });
      },
    });
    audiencias_calendar.render();
  }
  
  var plantoes_calendar_el = document.getElementById('agenda_plantoes');

  if (plantoes_calendar_el) {
    plantoes_calendar = new Calendar(plantoes_calendar_el, {
      plugins: [timeGridPlugin, dayGridPlugin, listPlugin],
      initialView: 'dayGridMonth',
      allDaySlot: false,
      slotEventOverlap: false,
      scrollTime: '00:00:00',
      locale: ptbrLocale,
      headerToolbar: {
        left: 'today prev,next',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        center: 'title'
      },
      buttonText: {
        list: 'Lista'
      },
      events: '/agendas/plantoes.json',
      eventContent: function (arg) {
        return {
          html: arg.event.title.replace(/\n/g, '<br>')
        }
      },
      eventDidMount: function(info) {
        tippy(info.el, {
          allowHTML: true,
          theme: 'light',
          interactive: true,
          maxWidth: 500,
          popperOptions: {
            positionFixed: true
          },
          appendTo: document.body,
          content: tooltip.innerHTML,
          onShow(instance) {
            instance.popper.querySelector('.titulo').innerHTML = info.event.extendedProps.inicio;
            instance.popper.querySelector('.inicio').innerHTML = info.event.extendedProps.inicio;
            instance.popper.querySelector('.fim').innerHTML = info.event.extendedProps.fim;
            instance.popper.querySelector('.desembargadorCivel').innerHTML = info.event.extendedProps.desembargadorCivel;
            instance.popper.querySelector('.desembargadorCriminal').innerHTML = info.event.extendedProps.desembargadorCriminal;
            instance.popper.querySelector('.desembargadorPleno').innerHTML = info.event.extendedProps.desembargadorPleno;
            instance.popper.querySelector('.camaraDireitoPublico').innerHTML = info.event.extendedProps.camaraDireitoPublico;
          }
        });
      },
    });
    plantoes_calendar.render();
  }

  var calendar_buttons = document.getElementsByClassName('fc-button-primary');
  while (calendar_buttons.length > 0) {
    calendar_buttons[0].classList.add("btn");
    calendar_buttons[0].classList.add("btn-danger");
    calendar_buttons[0].classList.remove("fc-button-primary");
  }
});

$(function () {
  $('#agenda_filter_unidade #codigo').on('change', function () {
    audiencias_calendar.refetchEvents();
  });
});

function agenda_url() {
  let p_id = $('#agenda_filter_unidade #codigo').val();
  let url = "/agendas/audiencias?";
  if (p_id != "") {
    let org = $('#agenda_filter_unidade #codigo').val();
    url = url + "codigo=" + p_id;
  }
  return url;
}
