import Highcharts from 'highcharts';
require("highcharts/modules/data")(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/offline-exporting")(Highcharts)
require("highcharts/modules/map")(Highcharts)
window.Highcharts = Highcharts;

import { piaui_data } from './piaui';
import { comarcas_data } from './comarcas';

document.addEventListener('DOMContentLoaded', function () {
  var element = document.getElementById('mapa_comarcas')

  if (element) {
    let labels = ('labels' in element.dataset) ? JSON.parse(element.dataset.labels) : [
      {
        from: 1,
        to: 1,
        name: "ENTRÂNCIA INICIAL",
        color: "#008080"
      },
      {
        from: 2,
        to: 2,
        name: "ENTRÂNCIA INTERMEDIÁRIA",
        color: "#e5e619"
      },
      {
        from: 3,
        to: 3,
        name: "ENTRÂNCIA FINAL",
        color: "#5353ec"
      }
    ]

    // console.log(JSON.parse(element.dataset.info))
    Highcharts.mapChart('mapa_comarcas', {
      chart: {
        borderWidth: 0
      },
      plotOptions: {
        series: {
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 0,
          shadow: false
        }
      },
      title: {
        text: null,
      },
      exporting: {
        sourceWidth: 600,
        sourceHeight: 500
      },
      legend: {
        title: {
          text: 'LEGENDA',
          style: {
            color: (Highcharts.theme && Highcharts.theme.textColor) || 'black'
          }
        },
        layout: 'vertical',
        align: 'right',
        floating: true,
        valueDecimals: 0,
        valueSuffix: '',
        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#fffafa',
        symbolRadius: 0,
        symbolHeight: 14
      },
      mapNavigation: {
        enabled: true
      },
      colorAxis: {
        dataClasses: labels
      },
      tooltip: {
        useHTML: true,
        hideDelay: 200,
        followPointer: true,
        formatter: function () {
          return `<div class='text-center'><b>${this.point.nome}</b><br><small>${this.point.nome2}</small></div>`;
          // return '<b>' + this.point.nome + '</b>';
        }
      },
      series: [{
        nullColor: '#f2f2f2',
        data: JSON.parse(element.dataset.info),
        mapData: piaui_data,
        joinBy: ['ibge', 'ibge'],
        name: 'Mapa das comarcas',
        borderWidth: 0.0,
        states: {
          hover: {
            color: "#D255DA",
            borderColor: "#000"
          }
        },
        point: {
          events: {
            click: function (ev) {
              console.log(ev.point)
            }
          }
        }
      }]
    });
  }

  $('.bootstrap-table-mapa').bootstrapTable({
    height: 740,
    showExport: true,
    search: true,
    striped: true,
    mobileResponsive: true,
    classes: 'table table-striped table-hover',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    }
  })
});

window.overComarca = function (element) {
  const label = element.text
  const nomes_municipios = JSON.parse(element.dataset.names)
  const municipios = nomes_municipios.map((x) => x.trim().toLowerCase().replace(/\s/g, '-').replace("'", ''))
  const municipioMap = $(`.highcharts-name-${municipios[0]}`)
  $("body").append(`<div id="popup-nome-comarca" style="border-radius: 6px;border: 2px solid;padding: .3rem;background-color: rgba(255,255,255,.8);position: absolute; left: ${municipioMap[0].getBoundingClientRect().left - 10}px; top: ${municipioMap[0].getBoundingClientRect().top + $(window).scrollTop() - 32}px;"><span><b>${label}</b></span></div>`)
  municipios.forEach(municipio => {
    $(`.highcharts-name-${municipio}`).css('fill', 'rgb(230,25,95)');
    $(`.highcharts-name-${municipio}`).css('stroke-width', '1px')
    $(`.highcharts-name-${municipio}`).attr('stroke', 'rgb(0,0,0)')
  })
}

window.outComarca = function (element) {
  const nomes_municipios = JSON.parse(element.dataset.names)
  const municipios = nomes_municipios.map((x) => x.trim().toLowerCase().replace(/\s/g, '-').replace("'", ''))
  municipios.forEach(municipio => {
    $(`.highcharts-name-${municipio}`).css('fill', '');
    $(`.highcharts-name-${municipio}`).css('stroke-width', '')
  $(`.highcharts-name-${municipio}`).attr('stroke', '')
  })
  $('#popup-nome-comarca').remove();
}

window.overCity = function (element) {
  const nome_municipio = element.dataset.name
  const municipio = nome_municipio.trim().toLowerCase().replace(/\s/g, '-').replace("'", '')
  const municipioMap = $(`.highcharts-name-${municipio}`)
  $("body").append(`<div id="popup-nome-municipio" style="border-radius: 6px;border: 2px solid;padding: .3rem;background-color: rgba(255,255,255,.8);position: absolute; left: ${municipioMap[0].getBoundingClientRect().left - 10}px; top: ${municipioMap[0].getBoundingClientRect().top + $(window).scrollTop() - 32}px;"><span><b>${nome_municipio}</b></span></div>`)
  $(`.highcharts-name-${municipio}`).css('fill', 'rgb(230,25,95)')
  $(`.highcharts-name-${municipio}`).css('stroke-width', '1px')
  $(`.highcharts-name-${municipio}`).attr('stroke', 'rgb(0,0,0)')
}

window.outCity = function (element) {
  const nome_municipio = element.dataset.name
  const municipio = nome_municipio.trim().toLowerCase().replace(/\s/g, '-').replace("'", '')
  $(`.highcharts-name-${municipio}`).css('fill', '');
  $(`.highcharts-name-${municipio}`).css('stroke-width', '')
  $(`.highcharts-name-${municipio}`).attr('stroke', '')
  $('#popup-nome-municipio').remove();
}

