import Sortable from 'sortablejs'
import Choices from 'choices.js'
import Inputmask from "inputmask"
import Cookies from 'js-cookie'

import toastr from 'toastr'

// jquery restante
$(function () {
  // selecionar a aba e colocar na url
  let hash = window.location.hash
  hash && $('ul.nav a[href="' + hash + '"]').tab('show')

  $('.nav-pills a').on('click', function (e) {
    $(this).tab('show')
    let scrollmem = $('body').scrollTop() || $('html').scrollTop()
    window.location.hash = this.hash
    $('html,body').scrollTop(scrollmem)
  })

  $('[data-toggle="tooltip"]').tooltip()

  $('#modelo_tabelas').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
    set_sortables(form[0])
  })

  $('#bill_batch').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
    mascaras_documentos()
  })

  $('.tabela_linhas').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
    numerar_linhas(e.currentTarget)
    mascaras_documentos()
  })

  $('#phones').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
    Inputmask("(99)9{4,5}\-9999").mask(insertedItem.find(".unit_phones_number input").toArray())
  })

  $('#emails').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
    Inputmask({ alias: "email" }).mask(insertedItem.find(".unit_emails_description input").toArray())
  })

  $('[data-provider="summernote"]').summernote({
    lang: 'pt-BR',
    theme: 'bs4',
    height: 300
  })

  $('input[id$=zipcode]').blur(function () {
    via_cep($(this))
  })

  set_select2()
  apply_select2_cities()

  document.querySelectorAll('#flash_messages div').forEach(elem => {
    let title = ''
    if (elem.dataset.kind == 'info') { title = 'Informação' }
    if (elem.dataset.kind == 'warning') { title = 'Aviso' }
    if (elem.dataset.kind == 'success') { title = 'Sucesso' }
    if (elem.dataset.kind == 'error') { title = 'Erro' }
    toastr[elem.dataset.kind](elem.dataset.text, title)
  })

  let tree_data = $('#SITEMAP').data('tree')

  $('#tree').jstree({
    'core': {
      'themes': {
        'name': 'proton',
        'responsive': true
      },
      'data': tree_data
    },
    "plugins": ["contextmenu"],
    "contextmenu": {
      "items": function ($node) {
        return {
          "show": {
            "label": "Visitar",
            "action": function (obj) {
              window.location = $node.a_attr.href;
            }
          }
        }
      }
    }
  })

  // Clickable ransack filters (list clicked)
  $('[id$=_search] .form-control').each(function () {
    var $this = $(this);

    if ($this.val() && $this.val().length > 0) {
      var ransackValue, filter;
      var ransackFieldId = $this.attr('id');

      var label = $('label[for="' + ransackFieldId + '"]');
      var label_text = label.text();

      if (label.length == 0) {
        label_text = $this.attr('placeholder');
      }

      if ($this.is('select')) {
        if ($this.attr('multiple') == 'multiple') {
          ransackValue = $this.find('option:selected').toArray().map(i => i.innerText).join(' ou ')
        } else {
          ransackValue = $this.find('option:selected').text()
        }
      } else if ($this.is('input[type=date]')) {
        ransackValue = $this.val()
        if (ransackValue) {
          ransackValue = new Date(ransackValue).toLocaleDateString('pt-BR')
        } 
      } else {
        ransackValue = $this.val()
      }

      label = label_text + ': ' + ransackValue;

      filter = '<span class="js-filter badge bg-danger" data-ransack-field="' + ransackFieldId + '">' + label;
      if ($this.attr('id') != 'q_s' && $this.attr('id') != 'q_discarded_at_not_null') {
        filter = filter + '&nbsp;<span class="fa fa-times js-delete-filter"></span>'
      }
      filter = filter + '</span>'
      $('.active-filters').append(filter).show();
    }
  });

  // Clickable ransack filters (remove)
  $(document).on('click', '.js-delete-filter', function () {
    var ransackField = $(this).parent().data('ransack-field');
    $('#' + ransackField).val('');
    $('[id$=_search]').trigger('submit');
  })
})

document.addEventListener('DOMContentLoaded', () => {
  set_sortables(document)

  Inputmask("(99)9{4,5}\-9999").mask(document.querySelectorAll(".unit_phones_number input"))
  Inputmask({ alias: "email" }).mask(document.querySelectorAll(".unit_emails_description input"))

  Inputmask.extendAliases({
    reais: {
      prefix: "R$ ",
      groupSeparator: ".",
      radixPoint: ',',
      alias: "numeric",
      placeholder: "0",
      autoGroup: !0,
      digits: 2,
      digitsOptional: !0,
      clearMaskOnLostFocus: !1
    }
  })

  mascaras_documentos()

  if (document.querySelector('.addon_icon') !== null) {
    document.querySelector('.addon_icon').addEventListener('keyup', (event) => {
      event.target.nextElementSibling.querySelector('svg').classList = ['fa ' + event.target.value]
    })
  }

  // select sem jquery
  if (document.querySelector('.choices') !== null) {
    new Choices('.choices', {
      paste: false,
      duplicateItemsAllowed: false,
      editItems: true,
      maxItemCount: 5,
      loadingText: 'Carregando...',
      noResultsText: 'Não encontrado',
      noChoicesText: 'Sem opções',
      itemSelectText: 'Clique para selecionar'
    })
  }

  var excelizar = document.querySelectorAll('.excel')
  for (var i = 0; i < excelizar.length; i++) {
    excelizar[i].addEventListener('click', (event) => {
      event.preventDefault()
      const data = event.target.parentElement.getElementsByTagName('textarea')[0].value
      const card_body = event.target.closest('.card-body')
      if (card_body.querySelector('table').dataset.removable == 'true') {
        const template_array = card_body.querySelector('.add_fields').dataset.associationInsertionTemplate.split('/>')

        let timestamp = Date.now()

        let content = ''

        data.split('\n').forEach((line, i) => {
          let line_array = template_array.slice(0) // duplicar o array
          line.split('\t').forEach((item, col) => {
            line_array[col] += "value='" + item.trim() + "'"
          })
          content += line_array.join(' />').replaceAll('new_documento_linhas', timestamp + i)
        })

        card_body.querySelector('table tbody').innerHTML += content
      } else {
        let idx = 0
        const inputs = event.target.parentElement.parentElement.parentElement.querySelectorAll('tr.nested-fields[data-removed=false] input[type=text]')
        data.split('\n').forEach((line) => {
          line.split('\t').forEach((item) => {
            if (inputs[idx]) {
              if (item.includes('-') && item.includes('R$')) {
                inputs[idx].value = -parseFloat(item.split('R$').slice(-1)[0].replace('.', '').replace(',', '.'))
              } else {
                inputs[idx].value = item
              }
              idx = idx + 1;
            }
          })
        })
      }
      numerar_linhas(card_body)
    })
  }

  bills_excelizar()
  
  var desexcelizar = document.querySelectorAll('.remove_excel')
  for (var i = 0; i < desexcelizar.length; i++) {
    desexcelizar[i].addEventListener('click', (event) => {
      event.preventDefault()
      const card_body = event.target.closest('.card-body')
      card_body.querySelectorAll('.remove_fields').forEach((el) => el.click())
    })
  }

  document.getElementById('increase_font').addEventListener('click', (event) => {
    event.preventDefault()
    change_zoom_level(10)
  })

  document.getElementById('decrease_font').addEventListener('click', (event) => {
    event.preventDefault()
    change_zoom_level(-10)
  })

  document.getElementById('default_font').addEventListener('click', (event) => {
    event.preventDefault()
    change_zoom_level(-1)
  })

  change_zoom_level(0)

  document.getElementById('high_contrast').addEventListener('click', (event) => {
    event.preventDefault()
    toggle_high_contrast()
  })
  toggle_high_contrast(0)
})

// indexar campos number nos sortables
function index_sortables() {
  var list_sortable = document.getElementsByClassName('ui-sortable')
  for (let stb of list_sortable) {
    var i = 1
    var inputs = stb.querySelectorAll("input[id$=_number]")
    for (let ipt of inputs) {
      ipt.value = i
      i = i + 1
    }
  }
}

// colocar elementos sortables na pagina
function set_sortables(parent) {
  var list_sortable = parent.getElementsByClassName('ui-sortable')
  for (let stb of list_sortable) {
    Sortable.create(stb, {
      animation: 150,
      ghostClass: 'bg-danger',
      onUpdate: function (e) {
        index_sortables()
      }
    })
  }
  index_sortables()
}

function mascaras_documentos() {
  document.querySelectorAll('[data-mask]').forEach((item) => {
    if (item.dataset.mask == "mask_money") {
      Inputmask({
        alias: 'reais'
      }).mask(item)
    } else {
      Inputmask({
        mask: item.dataset.mask
      }).mask(item)
    }
  })
}

function numerar_linhas(card_body) {
  card_body.querySelectorAll('tr input[id$=_number]').forEach((item, i) => {
    item.value = i + 1
  })
}

function toggle_high_contrast(toggle = 1) {
  let high_cont
  if (typeof Cookies.get('high_contrast') === 'undefined') {
    Cookies.set('high_contrast', 0)
  }
  if (toggle == 1) {
    high_cont = 1 - parseInt(Cookies.get('high_contrast'))
  } else {
    high_cont = parseInt(Cookies.get('high_contrast'))
  }
  var body = document.body;
  if (high_cont == 1) {
    body.classList.add('high-contrast')
  } else {
    body.classList.remove('high-contrast')
  }
  Cookies.set('high_contrast', high_cont)
}

function change_zoom_level(diff) {
  let zoomLevel
  if (typeof Cookies.get('zoomLevel') === 'undefined') {
    Cookies.set('zoomLevel', 100)
  }
  zoomLevel = parseInt(Cookies.get('zoomLevel'))
  if (diff == -1) {
    zoomLevel = 100
  } else {
    zoomLevel = zoomLevel + diff
  }
  var oDiv = document.body;
  if (typeof oDiv.style.MozTransform == "string") {
    oDiv = document.getElementById('the_content')
    oDiv.style.MozTransform = "scale(" + (zoomLevel / 100) + ")";
    oDiv.style.MozTransformOrigin = 'left top';
  } else if (typeof oDiv.style.zoom == "string") {
    oDiv.style.zoom = zoomLevel + "%";
  }
  Cookies.set('zoomLevel', zoomLevel)
}


window.set_select2 = function () {
  $(this).find('select.select2').select2('destroy')

  $("select.select2").select2({
    dropdownCssClass: 'select2-danger'
  })

}

function via_cep(input) {
  let form = input.parent().parent().parent()
  $.post("/utils/zipcode", { zipcode: input.val() }, function (data) {
    if (data) {
      form.find('input[id$=street]').val(data.data.street)
      form.find('input[id$=district]').val(data.data.neighborhood)
      form.find('input[id$=complement]').val(data.data.complement)
      if (data.city) {
        let select = form.find('select[id$=city_id]')
        let option = new Option(data.city.name, data.city.id, true, true)
        select.append(option).trigger('change')
      }
    }
  })
}

function apply_select2_cities() {
  $(".select2_cities").select2({
    placeholder: "Buscar Cidade",
    minimumInputLength: 3,
    language: "pt-BR",
    ajax: {
      type: 'post',
      dataType: 'json',
      url: "/utils/cities",
      processResults: function (data) {
        return {
          results: data
        }
      }
    }
  })
}

function bills_excelizar() {
  let excelizar = document.getElementById('bills_excel')
  if (excelizar) {
    excelizar.addEventListener('click', (event) => {
      event.preventDefault()
      const data = event.target.parentElement.getElementsByTagName('textarea')[0].value
      const box = event.target.closest('.card-danger')
      const btn_add = box.querySelector('.add_fields')

      data.split('\n').forEach((line, i) => {
        let line_items = line.split('\t')
        if (line_items.length < 2) {
          return
        }
        btn_add.click()
        const cards = box.querySelectorAll('.nested-fields')
        const new_line = cards[cards.length-1]
        new_line.querySelector('[id$=_bill_contract_id]').value = new_line.querySelector(`[id$=_bill_contract_id] > [data-registry="${line_items[0]}"]`).value
        new_line.querySelector('[id$=_bill_month]').value = line_items[1]
        new_line.querySelector('[id$=_reading]').value = line_items[2]
        new_line.querySelector('[id$=_total]').value = line_items[3]
        new_line.querySelector('[id$=_pis_cofins]').value = line_items[4]
        new_line.querySelector('[id$=_icms]').value = line_items[5]
        new_line.querySelector('[id$=_previous_reading]').value = line_items[6]
        new_line.querySelector('[id$=_account]').value = line_items[7]
        new_line.querySelector('[id$=_charges]').value = line_items[8]
        new_line.querySelector('[id$=_penalty]').value = line_items[9]
        new_line.querySelector('[id$=_correction]').value = line_items[10]
      })
    })
  }
}

global.moneySorter = function (a, b) {
  a = +a.replace('.', '').replace(',', '.').substring(3) // remove $
  b = +b.replace('.', '').replace(',', '.').substring(3)
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": true,
  "progressBar": false,
  "positionClass": "toast-top-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "5000",
  "timeOut": "10000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

global.toastr = toastr