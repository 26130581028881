import 'raphael/raphael'
import 'morris.js/morris'
import 'jquery.flot/jquery.flot'
import 'jquery.flot/jquery.flot.resize'
import 'jquery.flot/jquery.flot.pie'
import 'jquery.flot.tooltip/js/jquery.flot.tooltip'

$(document).ready(function () {
  $("#grafico_documento_id").on('change', function () {
    $.get('/documentos/' + $(this).val(), function (data) {
      if (data) {
        $("#grafico_items").find(".remove_fields").click();
        $.each(data, function (index, value) {
          $("#grafico_items").find(".add_fields").click();
          var linha = $("#grafico_items").find(".nested-fields:last");
          linha.find("select[id$=_modelo_coluna_id]").val(value['id']);
          linha.find("input[id$=_rotulo]").val(value['descricao']);
          linha.find("input[id$=_position]").val(index);
          linha.find("select[id$=_modelo_tipo_id]").val(value['modelo_tipo']['id']);
        });
      }
    }, "json");
  });

  if ($('a[data-toggle="tab"]').length == 0) {
    draw_graphics();
  }

  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    draw_graphics();
  });

  $('#tabs').on('cocoon:after-insert', function (e, form) {
    $(this).find(".tab_contents").on('cocoon:after-insert', function (e, form) {
      $(this).find("select[id$=_tab_type]").on('change', function () {
        ploa_select($(this));
      });
    });
  });

  $(".tab_contents").on('cocoon:after-insert', function (e, form) {
    $(this).find("select[id$=_tab_type]").on('change', function () {
      ploa_select($(this));
    });
  });

  $("select[id$=_tab_type]").on('change', function () {
    ploa_select($(this));
  });

});

function ploa_select(element) {
  var tipo = element.val();
  element.parent().parent().find("select[id$=_grafico_id]").parent().hide();
  element.parent().parent().find("select[id$=_documento_id]").parent().hide();
  element.parent().parent().find("textarea[id$=_content]").parent().hide();
  if (tipo == "grafico") {
    element.parent().parent().find("select[id$=_grafico_id]").parent().show();
  } else if (tipo == "tabela") {
    element.parent().parent().find("select[id$=_documento_id]").parent().show();
  } else if (tipo == "texto") {
    element.parent().parent().find("textarea[id$=_content]").parent().show();
    $('[data-provider="summernote"]').summernote({
      lang: 'pt-BR',
      height: 250,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['misc', ['undo', 'redo', 'fullscreen']]
      ]
    });
  }
}

function draw_graphics() {
  $("div[id^=morris_grafico_]").empty();
  $("div[id^=flot_grafico_]").empty();
  $.each($("div[id^=morris_grafico_]"), function (i, div) {
    if ($(div).data("tipo") == "line") {
      Morris.Line({
        element: div,
        data: $(div).data("dados"),
        xkey: $(div).data("xkey"),
        ykeys: $(div).data("ykeys"),
        labels: $(div).data("labels"),
        pointSize: 2,
        hideHover: 'auto',
        resize: true
      });
    } else if ($(div).data("tipo") == "bar") {
      Morris.Bar({
        element: div,
        data: $(div).data("dados"),
        xkey: $(div).data("xkey"),
        ykeys: $(div).data("ykeys"),
        ymin: $(div).data("yminimo"),
        labels: $(div).data("labels"),
        pointSize: 3,
        hideHover: 'auto',
        resize: true
      });
    } else if ($(div).data("tipo") == "donut") {
      var total_value = 0
      for (var i = 0; i < $(div).data("dados").length; i++) {
          total_value = total_value + $(div).data("dados")[i]["value"] // No modelo do documento o segundo campo devera ser value
      }
      Morris.Donut({
        element: div,
        data: $(div).data("dados"),
        colors: ["#0d1623", "#68ad43", "#ce1cbf", "#701a59", "#d1141e", "#1edbd5", "#a09e26", "#e5aca9", "#e943ef", "#1c6016"],
        formatter: function (value) { return formatCurrency(value) + " - " + ((value / total_value) * 100).toFixed(2) + "%" },
        resize: true
      });
    }
  });
  $.each($("div[id^=flot_grafico_]"), function (i, div) {
    if ($(div).data("tipo") == "pie") {
      // console.log($(div).data("dados"));
      var data = $(div).data("dados");
      var plotObj = $.plot($(div), data, {
          series: {
              pie: {
                  show: true
              }
          },
          grid: {
              hoverable: true
          },
          tooltip: true,
          tooltipOpts: {
              content: function(label,x,y){
                          return 'R$: ' + x + y+", "+label;
                      },
              shifts: {
                  x: 20,
                  y: 0
              },
              defaultTheme: false
          }
      });
    }
  });
}

function formatCurrency(total) {
  return parseFloat(total, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1.").toString();
}
