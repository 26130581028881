import 'bootstrap-table/dist/bootstrap-table'
// import 'tableexport.jquery.plugin'
import './tableExport'
// import 'bootstrap-table/dist/extensions/export/bootstrap-table-export'
import './bootstrap-table-export.js'
import 'bootstrap-table/dist/extensions/mobile/bootstrap-table-mobile'
import 'bootstrap-table/dist/locale/bootstrap-table-pt-BR'

jQuery(function () {
  show_tables()

  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    destroy_tables()
    show_tables()
  })
})

function destroy_tables() {
  $('.bootstrap-table1').bootstrapTable('destroy')
  $('.bootstrap-table-paginated').bootstrapTable('destroy')
  $('.bootstrap-table2').bootstrapTable('destroy')
  $('.bootstrap-table3').bootstrapTable('destroy')
  $('.bootstrap-table4').bootstrapTable('destroy')
  $('.bootstrap-table-ioi').bootstrapTable('destroy')
  $('.bootstrap-table-iod').bootstrapTable('destroy')
  $('.bootstrap-table5').bootstrapTable('destroy')
  $('.bootstrap-table-mobile').bootstrapTable('destroy')
  $('.bootstrap-table-mobile-without-export').bootstrapTable('destroy')
}

function show_tables() {
  // tamanho limitado
  $('.bootstrap-table1').bootstrapTable({
    height: 700,
    showExport: true,
    search: true,
    striped: true,
    mobileResponsive: true,
    classes: 'table table-striped table-hover',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    }
  })

  // paginado
  $('.bootstrap-table-paginated').bootstrapTable({
    showExport: true,
    pagination: true,
    search: true,
    striped: true,
    mobileResponsive: true,
    classes: 'table table-striped table-hover',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    }
  })

  // sem limite de tamanho
  $('.bootstrap-table2').bootstrapTable({
    striped: true,
    search: true,
    showExport: true,
    mobileResponsive: true,
    classes: 'table table-striped table-hover',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    },
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    }
  })

  // com paginacao e tamanho das celulas correspondendo aos dados
  $('.bootstrap-table3').bootstrapTable({
    striped: true,
    showExport: true,
    pagination: true,
    search: true,
    mobileResponsive: true,
    classes: 'table table-striped table-hover',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    exportDataType: 'all',
    formatNoMatches: function () {
      return 'SEM MOVIMENTO'
    }
  })

  $('.bootstrap-table3-pagination-after').bootstrapTable({
    classes: 'table table-striped table-hover',
    exportDataType: 'all',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    mobileResponsive: true,
    pagination: false,
    search: true,
    showExport: true,
    showFooter: true,
    showPaginationSwitch: true,
    striped: true,
    formatNoMatches: function () {
      return 'SEM MOVIMENTO'
    }
  })
  $('.bootstrap-table3-pagination-after').bootstrapTable('togglePagination')

  // sem paginacao e tamanho das celulas correspondendo aos dados
  $('.bootstrap-table4').bootstrapTable({
    striped: true,
    showExport: true,
    search: true,
    mobileResponsive: true,
    exportDataType: 'all',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    showColumns: 'true',
    showColumnsToggleAll: 'true',
    classes: 'table table-striped table-hover',
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    }
  })

  // bootstrap-table informacao ouvidoria informacaoes desclassificadas
  $('.bootstrap-table-ioi').bootstrapTable({
    striped: true,
    showExport: true,
    search: true,
    mobileResponsive: true,
    exportDataType: 'all',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    classes: 'table table-striped table-hover',
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    }
  })

  // bootstrap-table informacao ouvidoria documentos classificados
  $('.bootstrap-table-iod').bootstrapTable({
    striped: true,
    showExport: true,
    search: true,
    mobileResponsive: true,
    exportDataType: 'all',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    classes: 'table table-striped table-hover',
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    }
  })

  // sem paginacao e tamanho das celulas correspondendo aos dados e sem search
  $('.bootstrap-table5').bootstrapTable({
    striped: true,
    showExport: true,
    search: false,
    mobileResponsive: true,
    exportDataType: 'all',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    showColumns: 'true',
    showColumnsToggleAll: 'true',
    classes: 'table table-bordered table-striped table-hover',
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    }
  })

  $('.bootstrap-table-mobile').bootstrapTable({
    search: true,
    mobileResponsive: true,
    checkOnInit: true,
    showExport: true,
    exportDataType: 'all',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    classes: 'table table-striped table-hover table-bordered',
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    }
  })

  $('.bootstrap-table-mobile-without-export').bootstrapTable({
    checkOnInit: true,
    mobileResponsive: true,
    showExport: false,
    exportDataType: 'all',
    classes: 'table table-striped table-hover table-bordered',
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    }
  })

  // somente para exportar
  $('.bootstrap-table-hide').bootstrapTable({
    showExport: true,
    search: false,
    exportDataType: 'all',
    exportTypes: ['csv', 'json', 'doc', 'sql', 'txt', 'excel', 'xml'],
    formatNoMatches: function () {
      return $(this).attr('noMatchesMessage') || "Nenhum registro encontrado"
    },
    exportOptions: {
      onTableExportBegin: function (){
        $('.bootstrap-table-hide').removeClass('d-none')
      },
      onTableExportEnd: function (){
        $('.bootstrap-table-hide').addClass('d-none')
      }
    }
  })
}
