import 'stylesheets/application.scss'

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

import "@fortawesome/fontawesome-free/js/all"
import "@fortawesome/fontawesome-free/js/v4-shims"

import 'src/bootstrap_custom.js'
// import './src/jquery'
import 'src/adminlte.js'

import 'select2/dist/js/select2.full'
import 'select2/dist/js/i18n/pt-BR'

import 'overlayscrollbars/js/jquery.overlayScrollbars'

require("@nathanvda/cocoon")

import 'summernote/dist/summernote-bs4'
import 'summernote/lang/summernote-pt-BR'

import 'src/bootstrap_tables.js'

import 'jstree/dist/jstree'

import 'src/principal.js'

import 'src/agendas.js'
import 'src/graphics.js'
import 'src/search_forms.js'

import 'src/mapa_comarcas.js'